export default [
  { code: 'af_ZA', name: 'Afrikaans' },
  { code: 'gn_PY', name: 'Guaraní' },
  { code: 'ay_BO', name: 'Aymara' },
  { code: 'az_AZ', name: 'Azeri' },
  { code: 'id_ID', name: 'Indonesian' },
  { code: 'ms_MY', name: 'Malay' },
  { code: 'jv_ID', name: 'Javanese' },
  { code: 'bs_BA', name: 'Bosnian' },
  { code: 'ca_ES', name: 'Catalan' },
  { code: 'cs_CZ', name: 'Czech' },
  { code: 'ck_US', name: 'Cherokee' },
  { code: 'cy_GB', name: 'Welsh' },
  { code: 'da_DK', name: 'Danish' },
  { code: 'se_NO', name: 'Northern Sámi' },
  { code: 'de_DE', name: 'German' },
  { code: 'et_EE', name: 'Estonian' },
  { code: 'en_IN', name: 'English (India) ' },
  { code: 'en_PI', name: 'English (Pirate)' },
  { code: 'en_GB', name: 'English (UK)' },
  { code: 'en_UD', name: 'English (Upside Down)' },
  { code: 'en_US', name: 'English (US)' },
  { code: 'es_LA', name: 'Spanish' },
  { code: 'es_CL', name: 'Spanish (Chile)' },
  { code: 'es_CO', name: 'Spanish (Colombia)' },
  { code: 'es_ES', name: 'Spanish (Spain)' },
  { code: 'es_MX', name: 'Spanish (Mexico)' },
  { code: 'es_VE', name: 'Spanish (Venezuela)' },
  { code: 'eo_EO', name: 'Esperanto' },
  { code: 'eu_ES', name: 'Basque' },
  { code: 'tl_PH', name: 'Filipino' },
  { code: 'fo_FO', name: 'Faroese' },
  { code: 'fr_FR', name: 'French (France)' },
  { code: 'fr_CA', name: 'French (Canada)' },
  { code: 'fy_NL', name: 'Frisian' },
  { code: 'ga_IE', name: 'Irish' },
  { code: 'gl_ES', name: 'Galician' },
  { code: 'ko_KR', name: 'Korean ' },
  { code: 'hr_HR', name: 'Croatian' },
  { code: 'xh_ZA', name: 'Xhosa' },
  { code: 'zu_ZA', name: 'Zulu' },
  { code: 'is_IS', name: 'Icelandic ' },
  { code: 'it_IT', name: 'Italian' },
  { code: 'ka_GE', name: 'Georgian' },
  { code: 'sw_KE', name: 'Swahili' },
  { code: 'tl_ST', name: 'Klingon' },
  { code: 'ku_TR', name: 'Kurdish' },
  { code: 'lv_LV', name: 'Latvian' },
  { code: 'fb_LT', name: 'Leet Speak' },
  { code: 'lt_LT', name: 'Lithuanian' },
  { code: 'li_NL', name: 'Limburgish' },
  { code: 'la_VA', name: 'Latin' },
  { code: 'hu_HU', name: 'Hungarian' },
  { code: 'mg_MG', name: 'Malagasy' },
  { code: 'mt_MT', name: 'Maltese' },
  { code: 'nl_NL', name: 'Dutch' },
  { code: 'nl_BE', name: 'Dutch (België)' },
  { code: 'ja_JP', name: 'Japanese' },
  { code: 'nb_NO', name: 'Norwegian (bokmal)' },
  { code: 'nn_NO', name: 'Norwegian (nynorsk)' },
  { code: 'uz_UZ', name: 'Uzbek' },
  { code: 'pl_PL', name: 'Polish' },
  { code: 'pt_BR', name: 'Portuguese (Brazil)' },
  { code: 'pt_PT', name: 'Portuguese (Portugal)' },
  { code: 'qu_PE', name: 'Quechua' },
  { code: 'ro_RO', name: 'Romanian' },
  { code: 'rm_CH', name: 'Romansh' },
  { code: 'ru_RU', name: 'Russian' },
  { code: 'sq_AL', name: 'Albanian' },
  { code: 'sk_SK', name: 'Slovak' },
  { code: 'sl_SI', name: 'Slovenian' },
  { code: 'so_SO', name: 'Somali' },
  { code: 'fi_FI', name: 'Finnish' },
  { code: 'sv_SE', name: 'Swedish' },
  { code: 'th_TH', name: 'Thai' },
  { code: 'vi_VN', name: 'Vietnamese' },
  { code: 'tr_TR', name: 'Turkish' },
  { code: 'zh_CN', name: 'Simplified Chinese (China)' },
  { code: 'zh_TW', name: 'Traditional Chinese (Taiwan)' },
  { code: 'zh_HK', name: 'Traditional Chinese (Hong Kong)' },
  { code: 'el_GR', name: 'Greek' },
  { code: 'gx_GR', name: 'Classical Greek' },
  { code: 'be_BY', name: 'Belarusian' },
  { code: 'bg_BG', name: 'Bulgarian' },
  { code: 'kk_KZ', name: 'Kazakh' },
  { code: 'mk_MK', name: 'Macedonian' },
  { code: 'mn_MN', name: 'Mongolian' },
  { code: 'sr_RS', name: 'Serbian' },
  { code: 'tt_RU', name: 'Tatar' },
  { code: 'tg_TJ', name: 'Tajik' },
  { code: 'uk_UA', name: 'Ukrainian' },
  { code: 'hy_AM', name: 'Armenian' },
  { code: 'yi_DE', name: 'Yiddish' },
  { code: 'he_IL', name: 'Hebrew' },
  { code: 'ur_PK', name: 'Urdu' },
  { code: 'ar_AR', name: 'Arabic' },
  { code: 'ps_AF', name: 'Pashto' },
  { code: 'fa_IR', name: 'Persian' },
  { code: 'sy_SY', name: 'Syriac' },
  { code: 'ne_NP', name: 'Nepali' },
  { code: 'mr_IN', name: 'Marathi' },
  { code: 'sa_IN', name: 'Sanskrit' },
  { code: 'hi_IN', name: 'Hindi' },
  { code: 'bn_IN', name: 'Bengali' },
  { code: 'pa_IN', name: 'Punjabi' },
  { code: 'gu_IN', name: 'Gujarati' },
  { code: 'ta_IN', name: 'Tamil' },
  { code: 'te_IN', name: 'Telugu' },
  { code: 'kn_IN', name: 'Kannada' },
  { code: 'ml_IN', name: 'Malayalam' },
  { code: 'km_KH', name: 'Khmer' },
];
